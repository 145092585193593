<template>
    <div class="tab-container">
        <div class="tab-main">
            <div class="tab-font" :class="active == 0 ? 'active' : ''" @click="handelClick(0)">
                <img src="../../assets/img/mobileTerminal/yjs.png" alt="" />
                <span>{{ $route.query.type == 1 ? '意见书' : '文书' }}</span>
            </div>
            <div class="tab-font" :class="active == 1 ? 'active' : ''" @click="handelClick(1)">
                <img src="../../assets/img/mobileTerminal/xgws.png" alt="" />
                <span>相关文书</span>
            </div>
            <div class="tab-font" :class="active == 2 ? 'active' : ''" @click="handelClick(2)">
                <img src="../../assets/img/mobileTerminal/xsal.png" alt="" />
                <span>相似案例</span>
            </div>
            <div class="tab-font" :class="active == 3 ? 'active' : ''" @click="handelClick(3)">
                <img src="../../assets/img/mobileTerminal/flyj1.png" alt="" />
                <span>法律依据</span>
            </div>
            <div
                class="tab-font"
                :class="active == 4 ? 'active' : ''"
                @click="handelClick(4)"
                v-if="litigationGuidelines"
            >
                <img src="../../assets/img/mobileTerminal/sszy.png" alt="" />
                <span>诉讼指引</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MobileTerminal',
        props: ['litigationGuidelines'],
        data() {
            return {
                active: 0,
            }
        },
        mounted() {
            this.$emit('activeTab', this.active)
        },
        methods: {
            handelClick(event) {
                this.active = event
                this.$emit('activeTab', this.active)
            },
        },
    }
</script>

<style scoped lang="less">
    .tab-container {
        padding: 0.3rem 0.3rem 0 0.3rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100vw;
        height: 1.72rem;
        background-color: #0043a7;
        .tab-main {
            display: flex;
            height: 100%;

            .tab-font {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-top: 0.06rem;
                }

                span {
                    font-size: 0.26rem;
                    font-weight: bold;
                    color: rgba(255, 255, 255, 0.9);
                    margin-top: 0.1rem;
                }
            }
        }

        .active {
            background-color: #ffa525;
            border-radius: 10px 10px 0px 0px;
        }
    }
</style>
