<template>
    <div id="MobileTerminal">
        <Tab @activeTab="activeTab" :litigationGuidelines="litigationGuidelines"></Tab>
        <div class="mobileTerminal-main">
            <GenerateOpinions v-if="list[0]" v-show="active == 0"></GenerateOpinions>
            <RelatedDocuments v-if="list[1]" v-show="active == 1"></RelatedDocuments>
            <SimilarCase v-if="list[2]" v-show="active == 2"></SimilarCase>
            <Law v-if="list[3]" v-show="active == 3"></Law>
            <LitigationGuidelines
                v-show="active == 4"
                @changeLitigationGuidelines="changeLitigationGuidelines"
            ></LitigationGuidelines>
        </div>
    </div>
</template>

<script>
    import Tab from '@/components/mobileTerminal/Tab'
    import GenerateOpinions from '@/components/mobileTerminal/GenerateOpinions'
    import Law from '@/components/mobileTerminal/Law'
    import RelatedDocuments from '@/components/mobileTerminal/RelatedDocuments'
    import SimilarCase from '@/components/mobileTerminal/SimilarCase'
    import LitigationGuidelines from '@/components/mobileTerminal/LitigationGuidelines'
    export default {
        name: 'mobileTerminal',
        components: {
            Tab,
            GenerateOpinions,
            Law,
            RelatedDocuments,
            SimilarCase,
            LitigationGuidelines,
        },
        data() {
            return {
                active: 0,
                list: [true, false, false, false, false],
                litigationGuidelines: false,
            }
        },
        methods: {
            activeTab(data) {
                this.active = data
                this.list[data] = true
                document.getElementsByClassName('main')[0].scrollTo(0, 0)
            },
            changeLitigationGuidelines(data) {
                this.litigationGuidelines = data
            },
        },
    }
</script>

<style scoped lang="less">
    #MobileTerminal {
        width: 100vw;
        min-height: 100vh;
        background-color: #0043a7;
        padding: 0 0.3rem;
        overflow-x: hidden;
        display: flex;
        .mobileTerminal-main {
            margin-top: 1.72rem;
            flex: 1;
            background-color: #fff;
        }
    }
</style>
