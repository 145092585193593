<template>
    <div class="law-content">
        <div class="docs" v-if="!loading && docs.length > 0">
            <div class="box" v-for="(item, index) in docs" :key="index">
                <div class="title">{{ item.lawRegulationName }}</div>
                <div class="label"
                    ><span>{{ item.terms }}</span></div
                >
                <div v-html="item.content" class="cont"></div>
            </div>
        </div>

        <div v-else-if="loading" class="notFont">
            <img src="../../assets/img/mobileTerminal/loading.gif" alt="" />
        </div>

        <div v-else class="notFont">
            <img src="../../assets/img/mobileTerminal/notFont.png" alt="" />
            <span>暂无数据</span>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'
    import { apiList } from '@/api/apiList'

    export default {
        name: 'Law',
        data() {
            return {
                docs: [],
                loading: true,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            async getList() {
                //请求数据
                let data = {
                    method: 'GET',
                    url:
                        apiList.mobileGetLegalBasis +
                        '/' +
                        this.$route.query.moduleId +
                        '/' +
                        this.$route.query.resultId,
                    config: {},
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                this.loading = false
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    if (dataSource.data.returnData.length > 0) {
                        dataSource.data.returnData.forEach((el) => {
                            el.content = el.content.split('\n').join('<br/>')
                        })
                        this.docs = dataSource.data.returnData
                    }
                }
            },
        },
    }
</script>

<style lang="less">
    .law-content {
        .docs {
            float: left;
            height: 740px;
            width: 100%;
            background: #fff;
            overflow: auto;
            .box {
                position: relative;
                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                .title {
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;
                    font-weight: bold;
                    padding: 0.3rem 0.25rem 0.1rem;
                }
                .label {
                    font-size: 12px;
                    line-height: 26px;
                    color: #fff;
                    margin: 5px 0.4rem 12px;
                    span {
                        border-radius: 4px;
                        background: #358cf3;
                        padding: 0.09rem 0.15rem;
                    }
                }

                .cont {
                    padding: 0 0.3rem 0.3rem;
                    line-height: 26px;
                    font-size: 14px;
                    color: #4f555b;
                    word-break: break-all;
                    word-wrap: break-word;
                }
            }
        }
    }
    .box:after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: ' ';
        width: 100%;
        height: 0;
        border-bottom: 1px solid #ccc;
        transform-origin: 0 0;
        transform: scaleY(0.5);
    }
    .notFont {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 200px;
        margin: 50px auto 0;
        img {
            width: 200px;
            height: 200px;
        }
    }
</style>
