<template>
    <div>
        <div class="docs-mobile" v-if="!loading && docs.length > 0">
            <a
                :href="item.address"
                :download="item.documentName"
                v-for="(item, index) in docs"
                :key="index"
            >
                {{ item.documentName }}
            </a>
        </div>
        <div v-else-if="loading" class="notFont">
            <img src="../../assets/img/mobileTerminal/loading.gif" alt="" />
        </div>
        <div v-else class="notFont">
            <img src="../../assets/img/mobileTerminal/notFont.png" alt="" />
            <span>暂无数据</span>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'
    import { apiList } from '../../api/apiList'
    export default {
        name: 'RelatedDocuments',
        data() {
            return {
                docs: [],
                loading: true,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            async getList() {
                //请求数据
                let data = {
                    method: 'GET',
                    url: apiList.getDownload + '/' + this.$route.query.moduleId,
                    config: {},
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                this.loading = false
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    if (dataSource.data.returnData.length > 0) {
                        this.docs = dataSource.data.returnData
                    }
                }
            },
        },
    }
</script>

<style lang="less">
    .docs-mobile {
        width: 100%;
        a {
            width: 100%;
            line-height: 0.4rem;
            float: left;
            font-size: 14px;
            color: #0088e8;
            text-decoration: underline;
            cursor: pointer;
            padding: 0.5rem 0.3rem;
        }
    }
    .notFont {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 200px;
        margin: 50px auto 0;
        img {
            width: 200px;
            height: 200px;
        }
    }
</style>
